import { graphql } from "gatsby";
import React from "react";
export default function NotFound() {
  if (typeof window !== 'undefined') {
    window.location = '/';
  }

  return null;
}
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;  






